<template>
  <div class="page-container">
    <el-row style="margin-bottom: 10px">
      <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1">
        <el-button type="primary" @click="dialogTableVisible = true">任务列表</el-button>
      </el-col>
      <el-col :xs="8" :sm="6" :md="4" :lg="3" :xl="1">
        <el-button type="success" @click="insertData">新增任务</el-button>
      </el-col>
    </el-row>
    <!-- 表单 -->
    <el-form ref="form" :model="formData" label-width="80px">
      <el-form-item label="模板类型:">
        <el-select v-model="formData.type" placeholder="请选择">
          <el-option
              v-for="item in templateType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="模板ID:">
        <el-input v-model="formData.template_id"/>
      </el-form-item>
      <el-form-item label="跳转地址:">
        <el-input v-model="formData.url"/>
      </el-form-item>
      <el-row>
        <el-col :span="4">
          <el-form-item label="topColor:">
            <el-color-picker v-model="formData.topcolor"/>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item v-for="(item, index) in formData.toUser" :key="index + 'j'">
         <span slot="label">
           <el-tag closable @close="removeUser(index)"> {{ index + 1 + '个用户' }}</el-tag>

    </span>
        <el-input v-model="item.openId"/>
      </el-form-item>
      <el-divider></el-divider>
      <div v-for="(input, indexI) in formData.data" :key="indexI">
        <el-row>
          <el-col :span="12">
            <el-form-item label="Key值:">
              <el-input v-model="input.keyValue"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="系统变量:">
              <el-select v-model="input.type" placeholder="请选择">
                <el-option label="自定义" value=""></el-option>
                <el-option label="天气" value="c_weather"></el-option>
                <el-option label="天气-温度" value="c_weather_temp"></el-option>
                <el-option label="穿衣指数" value="c_indices_3"></el-option>
                <el-option label="紫外线指数" value="c_indices_5"></el-option>
                <el-option label="舒适度指数" value="c_indices_8"></el-option>
                <el-option label="当前时间" value="c_time"></el-option>
                <el-option label="相差天数" value="c_intervalTime"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="18">
            <el-form-item label="Text:">
              <el-input type="textarea" v-model="input.value"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4" :offset="2">
            <el-color-picker v-model="input.color"></el-color-picker>
          </el-col>
        </el-row>
        <el-divider>
          <el-tag closable @close="removeData(indexI)">删除{{ input.keyValue }}</el-tag>
        </el-divider>
      </div>
      <el-row type="flex" class="row-bg" justify="center" style="text-align: center">
        <el-col :span="8">
          <el-button type="primary" @click="addInput1">增加联系人</el-button>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="addInput">增加key</el-button>
        </el-col>
        <el-col :span="8">
          <el-button type="primary" @click="save">保存</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-dialog title="列表" :visible.sync="dialogTableVisible" width="100%">
      <el-table :data="gridData">
        <el-table-column property="vcId" label="任务ID"></el-table-column>
        <el-table-column property="vcContentData.template_id" label="模板ID"></el-table-column>
        <el-table-column label="模板类型">
          <template slot-scope="scope">
            {{ scope.row.vcContentData.type === 'sub' ? '订阅模板' : '普通模板' }}
          </template>
        </el-table-column>

        <el-table-column
            fixed="right"
            label="操作"
            width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row.vcContentData)" type="text" size="small">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import WeChat from "@/request/weChat";

export default {
  data() {
    return {
      ask: new WeChat(),
      dialogTableVisible: false,
      insertInfo: true,
      gridData: [],
      formData: {
        type: "sub",
        template_id: "",
        url: "",
        topcolor: "",
        toUser: [{"openId": ""}],
        data: [{
          keyValue: '',
          type: '',
          value: '',
          color: "#409EFF"
        }]
      },
      templateType: [
        {
          value: 'sub',
          label: '订阅模板'
        },
        {
          value: 'mode',
          label: '普通模板'
        }
      ]
    }
  },
  mounted() {
    this.ask.getTemplate().then(res => {
      if (res.code === 'success' && res.msg != null) {
        this.gridData = res.msg
      }
    })
  },
  methods: {
    insertData() {
      this.insertInfo = true;
      this.formData = {
        type: "sub",
        template_id: "",
        url: "",
        topcolor: "",
        toUser: [{"openId": ""}],
        data: [{
          keyValue: '',
          type: '',
          value: '',
          color: "#409EFF"
        }]
      }
    },
    handleClick(resultData) {
      this.insertInfo = false;
      this.formData.template_id = resultData.template_id
      this.formData.url = resultData.url
      this.formData.topcolor = resultData.topcolor
      this.formData.toUser = []
      this.formData.type = resultData.type
      resultData.toUser.forEach(item => {
        this.formData.toUser.push({"openId": item})
      })
      this.formData.data = []
      for (let key in resultData.data) {
        this.formData.data.push({
          keyValue: key,
          value: resultData.data[key].value,
          color: resultData.data[key].color,
          type: resultData.data[key].type
        })
      }
      this.dialogTableVisible = false;
    },
    addInput1() {
      this.formData.toUser.push({"openId": ""})
    },
    removeUser(index) {
      this.formData.toUser.splice(index, 1)
    },
    addInput() {

      this.formData.data.push({
        keyValue: '',
        type: '',
        value: '',
        color: "#409EFF"
      })
    },
    removeData(index) {
      this.formData.data.splice(index, 1)
    },
    save() {
      let data = {
        template_id: this.formData.template_id,
        url: this.formData.url,
        topcolor: this.formData.topcolor,
        type: this.formData.type,
        toUser: [],
        data: {}
      }
      // 用户信息openId
      this.formData.toUser.forEach(item => {
        data.toUser.push(item.openId);
      })
      // data 键值
      this.formData.data.forEach(item => {
        data.data[item.keyValue] = {
          value: item.value,
          color: item.color,
          type: item.type
        }
      })
      if (this.insertInfo) {
        this.ask.tempLateAdd(data).then(res => {
          this.$message({
            message: '恭喜你，这是一条成功消息',
            type: 'success'
          });
        })
        return
      }
      this.ask.tempLateUpd(data).then(res => {
        this.$message({
          message: '恭喜你，修改成功!',
          type: 'success'
        });
      })

    }
  }
}
</script>
